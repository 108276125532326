code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* UniversNextforHSBC-100 - Thin */
@font-face {
  font-family: "UniversNextforHSBC";
  font-style: normal;
  font-weight: 100;
  src: local(""),
    url("assets/fonts/UniversNextforHSBC-Thin.otf") format("opentype");
}

/*UniversNextforHSBC-300 - Light */
@font-face {
  font-family: "UniversNextforHSBC";
  font-style: normal;
  font-weight: 300;
  src: local(""),
    url("assets/fonts/UniversNextforHSBC-Light.otf") format("opentype");
}

/* UniversNextforHSBC-regular - Regular */
@font-face {
  font-family: "UniversNextforHSBC";
  font-style: normal;
  font-weight: 400;
  src: local(""),
    url("assets/fonts/UniversNextforHSBC-Regular.otf") format("opentype");
}

/* UniversNextforHSBC-500 - Medium */
@font-face {
  font-family: "UniversNextforHSBC";
  font-style: normal;
  font-weight: 500;
  src: local(""),
    url("assets/fonts/UniversNextforHSBC-Medium.otf") format("opentype");
}

/* UniversNextforHSBC-700 - bold */
@font-face {
  font-family: "UniversNextforHSBC";
  font-style: normal;
  font-weight: 700;
  src: local(""),
    url("assets/fonts/UniversNextforHSBC-Bold.otf") format("opentype");
}
